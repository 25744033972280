<template>
  <div class="grid">
		<div class="col-12">
            <div class="card">
                <Toast/>
                <Message ref="message" severity="error" :closable="true" v-if="errors.length">{{errors}}</Message>

                <Fieldset legend="Add New" :toggleable="true" :collapsed="true" class="mb-3" v-if="permission.includes('create')">
                    <div class="p-fluid">
                        <div class="formgrid grid">
                            <div class="field col-12 md:col-6 lg:col-3">
                                <label for="username">Username</label>
                                <InputText id="username" v-model="forms.username" required="true" autofocus :class="{ 'p-invalid': errorAdd.username }" />
                                <small class="p-invalid" style="color: red" v-if="errorAdd.username" >{{ errorAdd.username[0] }}</small >
                            </div>
                            <div class="field col-12 md:col-6 lg:col-3">
                                <label for="name">Name</label>
                                <InputText id="name" v-model="forms.name" required="true" autofocus :class="{ 'p-invalid': errorAdd.name }" />
                                <small class="p-invalid" style="color: red" v-if="errorAdd.name" >{{ errorAdd.name[0] }}</small>
                            </div>
                            <div class="field col-12 md:col-6 lg:col-3">
                                <label for="db_id">Distributor</label>
                                <MultiSelect id="db_id" dataKey="db_id" v-model="forms.db_id" :loading="loadingDropdownDistributorAdd" :options="dataDropdownDistributorAdd" :class="{ 'p-invalid': errorAdd.db_id }" optionLabel="db_name" placeholder="Choose Distributor" :showToggleAll="false" :filter="true" :showClear="true" @filter="searchDropdownDistributor($event ,'add')"/>
                                <small class="p-invalid" style="color: red" v-if="errorAdd.db_id" >{{ errorAdd.db_id[0] }}</small>
                            </div>
                        </div>
                    </div>
                    <Button :loading="loadingAddNew" label="Save" icon="pi pi-save" class="p-button-primary" @click="saveNew" />
                </Fieldset>

                <DataTable :value="dataTable" responsiveLayout="scroll" :loading="loading" dataKey="number" @sort="onSort($event)" >
                    <template #header>
                        <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                            <div>
                                <Button :loading="loadingExcel" label="Export Excel" icon="pi pi-file-excel" class="p-button p-button-success mr-2 my-1 inline-block" @click="exportExcelCSV('xlsx')" />
                                <Button :loading="loadingCsv" label="Export CSV" icon="pi pi-file-excel" class="p-button p-button-success mr-2 my-1 inline-block" @click="exportExcelCSV('csv')" />
                            </div>
                            <span class="block mt-2 md:mt-0 p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="search" placeholder="Search..." @keyup.enter="getDataTable" />
                            </span>
                        </div>
                    </template>
                    <Column field="number" header="#" :style="{width:'20px'}" >
                        <template #body="slotProps">
                        {{ slotProps.data.number }}
                        </template>
                    </Column>
                    <Column field="username" header="Username">
                        <template #body="slotProps">
                            {{slotProps.data.userweb_username}}
                        </template>
                    </Column>
                    <Column field="userweb_nama" header="Name">
                        <template #body="slotProps">
                            {{slotProps.data.userweb_nama}}
                        </template>
                    </Column>
                    <Column field="db_label" header="Distributor">
                        <template #body="slotProps">
                            {{slotProps.data.db_label}}
                        </template>
                    </Column>
                    <Column field="status_" header="Status">
                        <template #body="slotProps">
                            <Button icon="pi pi-check" class="p-button-rounded" v-if="slotProps.data.status_ == 1"/>
                            <Button icon="pi pi-times" class="p-button-rounded  p-button-danger" v-else/>
                        </template>
                    </Column>
					<Column header="Action" headerStyle="min-width:10rem;">
						<template #body="slotProps">
							<Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2" @click="editItem(slotProps.data)" v-if="permission.includes('update')"/>
						</template>
					</Column>
                    <template #empty>
                        No data found.
                    </template>
                    <template #loading>
                        Loading data. Please wait.
                    </template>
                    <template #footer>
                        In total there are {{totalItemsCount ? totalItemsCount : 0 }} data.
                    </template>
                </DataTable>

                <Paginator v-model:rows="rows" v-model:first="offset" :totalRecords="totalItemsCount" :rowsPerPageOptions="[10,20,30]">
                    <template>
                        {{totalItemsCount}}
                    </template>
                </Paginator>

				<Dialog v-model:visible="editItemDialog" :style="{width: '450px'}" header="Detail" :modal="true" class="p-fluid">
					<div class="field">
						<label for="Username">Username</label>
                        <InputText id="username" v-model="item.userweb_username" required="true" autofocus :class="{ 'p-invalid': errorEdit.userweb_username }" />
                        <small class="p-invalid" style="color: red" v-if="errorEdit.userweb_username" >{{ errorEdit.userweb_username[0] }}</small >
					</div>
					<div class="field">
						<label for="nama">Name</label>
                        <InputText id="nama" v-model="item.userweb_nama" required="true" autofocus :class="{ 'p-invalid': errorEdit.userweb_nama }" />
                        <small class="p-invalid" style="color: red" v-if="errorEdit.userweb_nama" >{{ errorEdit.userweb_nama[0] }}</small >
					</div>
					<div class="field">
                        <label for="db_id">Distributor</label>
                        <MultiSelect id="db_id" ref="ddistributor" dataKey="db_id" v-model="item.db_id" :options="dataDropdownDistributorEdit"  :loading="loadingDropdownDistributorEdit" :class="{ 'p-invalid': errorEdit.db_id }" optionLabel="db_name" placeholder="Choose Distributor" :showToggleAll="false" :filter="true" @filter="searchDropdownDistributor($event ,'edit')" />
                        <small class="p-invalid" style="color: red" v-if="errorEdit.db_id" >{{ errorEdit.db_id[0] }}</small>
					</div>
					<div class="field">
                        <label for="status">Status</label>
                        <Dropdown id="status" v-model="item.status_" :options="dataDropdownStatusEdit" :class="{ 'p-invalid': errorEdit.status_ }" optionLabel="name" optionValue="code"/>
                        <small class="p-invalid" style="color: red" v-if="errorEdit.status_" >{{ errorEdit.status_[0] }}</small>
					</div>
					<template #footer>
						<Button label="Cancel" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
						<Button label="Save" icon="pi pi-check" class="p-button-text" @click="updateItem" :loading="loadingEdit"/>
					</template>
				</Dialog>
            </div>
      </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from "vuex";
import { numberingDatatable } from '../../utils/helpers';

export default {
    data() {
        return {
            // loading
            loading: false,
            loadingAddNew: false,
            loadingEdit: false,
            loadingDropdownDistributorAdd: false,
            loadingDropdownDistributorEdit: false,
            loadingExcel: false,
            loadingCsv: false,

            // dataDropdown
            dataDropdownDistributorAdd: null,
            dataDropdownDistributorEdit: null,
            dataDropdownStatusEdit: [
                {name: 'Active', code: 1},
                {name: 'Deactive', code: 0},
            ],

            // addNew
            forms: {
                username: null,
                name: null,
                db_id: null,
            },

            // edit
            item: {},
            itemDistributor: [],
            editItemDialog: false,
            

            //datatables
            data: null,
            dataTable: null,
            rows: 10,
            search: null,
            offset: null,
            field: null,
            sort: null,
            page: null,
            totalItemsCount: 0,
        }
    },
    mounted() {
        this.getDataTable();
        this.$store.commit('setErrors', {});
        this.$store.commit('setErrorAdd', {});
        this.$store.commit('setErrorEdit', {});
        this.searchDropdownDistributor('');
    },
    watch: {
        offset() {
            this.getDataTable();
        },
        rows() {
            this.getDataTable();
        },
    },
    computed:{
        ...mapGetters(['errors', 'errorEdit', 'errorAdd']),

		...mapGetters("auth", ["permission"]),
    },
    methods: {
        formatDate(value){
            if (value) {
                return moment.utc(value).format('DD-MM-YYYY')
            }
        },
        convertMultiSelectDistributor(data) {
            return data.map((item) => {
                return item.db_id;
            });
        },
        // DROPDOWN
        searchDropdownDistributor(event, purpose, valueEdit){
            setTimeout(() => {

                if(valueEdit){
                    this.$refs.ddistributor.filterValue = valueEdit;
                }
                
                if(purpose == "add"){
                    this.loadingDropdownDistributorAdd = true;
                }else if(purpose == "edit"){
                    this.loadingDropdownDistributorEdit = true;
                }

                this.axios({
                    method: 'GET',
                    url: process.env.VUE_APP_ROOT_API + 'web/select2/master-distributor',
                    params: {
                        "search" : valueEdit ? valueEdit : event.value,
                    }
                })
                .then(res => {

                    if(purpose == "add"){
                        this.dataDropdownDistributorAdd = res.data.data;
                        this.loadingDropdownDistributorAdd = false;

                        //Multiple Selected
                        if(this.forms.db_id){
                            this.dataDropdownDistributorAdd.push(...this.forms.db_id);

                            const uniqueids= [];
                            this.dataDropdownDistributorAdd = this.dataDropdownDistributorAdd.filter((c) => {
                                const isDup = uniqueids.includes(c.db_id);
                                if(!isDup){
                                    uniqueids.push(c.db_id);
                                    return true;
                                } 
                                return false;
                            });
                        }
                    }else if(purpose == "edit"){
                        this.dataDropdownDistributorEdit = res.data.data;
                        this.loadingDropdownDistributorEdit = false;


                        //Multiple Selected
                        if(this.item.db_id){
                            this.dataDropdownDistributorEdit.push(...this.item.db_id);

                            const uniqueids= [];
                            this.dataDropdownDistributorEdit = this.dataDropdownDistributorEdit.filter((c) => {
                                const isDup = uniqueids.includes(c.db_id);
                                if(!isDup){
                                    uniqueids.push(c.db_id);
                                    return true;
                                } 
                                return false;
                            });
                        }
                        
                    }else if(purpose == null){
                        this.dataDropdownDistributorAdd = res.data.data;
                        this.dataDropdownDistributorEdit = res.data.data;
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
            }, 250);
        },
        // ADDNEW
        saveNew() {
            this.loadingAddNew = true;

            this.axios({
                method: 'POST',
                url: process.env.VUE_APP_ROOT_API + 'web/master-userweb/create',
                data: {
                    "username": this.forms.username,
                    "name": this.forms.name,
                    "db_id": this.forms.db_id !== null ? this.convertMultiSelectDistributor(this.forms.db_id) : null,
                    "status_": 1,
                },
            })
            .then((res) => {
                this.loadingAddNew = false;
                if (res) {
                    this.$toast.add({ severity: 'success', summary: 'Successful', detail: 'Data Successfully Saved', life: 3000,});
                    this.$store.commit('setErrors', {});
                    this.$store.commit('setErrorAdd', {});
                    this.clearForms();
                    this.getDataTable();
                }
            })
            .catch((err) => {
                console.log(err);
                this.loadingAddNew = false;
                this.$store.commit('setErrorAdd', err.response.data.data);
                this.$toast.add({ severity: 'error', summary: 'Failed', detail: 'Data Failed to Save', life: 3000,});
            });
        },
        clearForms() {
            this.forms.username = null;
            this.forms.name = null;
            this.forms.db_id = null;
        },
        // EDIT
		editItem(item) {
			this.item = item;
            
            // dropdown 
            this.searchDropdownDistributor('', 'edit');

            // multiple select distributor
            this.itemDistributor = [];
            item.masterDistributor.map((item) => {
                return this.itemDistributor.push(item);
            });
            this.item = {...this.item, "db_id": this.itemDistributor }

			this.editItemDialog = true;
            this.$store.commit('setErrorEdit', {});

		},
		hideDialog() {
			this.editItemDialog = false;
			this.submitted = false;
		},
		updateItem() {
            this.loadingEdit = true;

            this.axios({
                method: 'POST',
                url: process.env.VUE_APP_ROOT_API + 'web/master-userweb/update',
                data: {
                    "userweb_id" : this.item.userweb_id,
                    "username" : this.item.userweb_username,
                    "name" : this.item.userweb_nama,
                    "db_id": this.item.db_id !== null ? this.convertMultiSelectDistributor(this.item.db_id) : null,
                    "status_" : this.item.status_,
                },
            })
            .then((res) => {
                this.loadingEdit = false;
                if (res) {
                    this.$toast.add({ severity: 'success', summary: 'Successful', detail: 'Data Successfully Updated', life: 3000,});
                    this.$store.commit('setErrors', {});
                    this.$store.commit('setErrorEdit', {});
                    this.item = {};
                    this.editItemDialog = false;
                    this.getDataTable();
                }
            })
            .catch((err) => {
                console.log(err);
                this.loadingEdit = false;
                this.$store.commit('setErrorEdit', err.response.data.data);
                this.$toast.add({ severity: 'error', summary: 'Failed', detail: 'Data Failed to Updated', life: 3000,});
            });
		},
        // DATATABLE
        getDataTable(){
          
            this.loading=true;
            this.page = (this.offset / this.rows) + 1;

            this.axios({
                method: 'GET',
                url: process.env.VUE_APP_ROOT_API + 'web/master-userweb',
                params: {
                    "search" : this.search,
                    "per_page" : this.rows,
                    "page" : this.page,
                    "order_by" : this.field,
                    "sort_by" : this.sort,
                }
            })
            .then(res => {
              this.data = res.data.data.data;
              this.dataTable = numberingDatatable(res.data.data.data, this.page, this.rows);
              this.totalItemsCount = res.data.data.total;
              this.rows = parseInt(res.data.data.per_page);
              this.loading=false;
            })
            .catch((err) => {
                console.log(err);
            });
         
        },
        onSort(event) {
            this.field = event.sortField;
            this.sort = event.sortOrder == '1' ? 'ASC' : 'DESC';
            this.getDataTable();
        },
        // EXPORT
        exportExcelCSV(ext){

            if(ext == 'xlsx'){
                this.loadingExcel=true;
            }else if(ext == 'csv'){
                this.loadingCsv=true;
            }

            this.axios({
            method: 'GET',
            url: process.env.VUE_APP_ROOT_API + 'web/master-userweb/export',
            responseType: 'blob',
            params: {
                "ext" : ext,
            }
            })
            .then(response => {
                let fileUrl = window.URL.createObjectURL(response.data);
                let fileLink = document.createElement('a');

                fileLink.href = fileUrl;

                fileLink.setAttribute('download', 'Web Users Export.' + ext);
                
                document.body.appendChild(fileLink)

                fileLink.click();
                
                if(ext == 'xlsx'){
                    this.loadingExcel=false;
                }else if(ext == 'csv'){
                    this.loadingCsv=false;
                }

            })
            .catch((err) => {
                console.log(err);
                
                if(ext == 'xlsx'){
                    this.loadingExcel=false;
                }else if(ext == 'csv'){
                    this.loadingCsv=false;
                }
            });
        },
    }
}
</script>
